<template>
  <div class="py-5">
    <div class="row pb-5">
      <div class="col-md-8">
        <div class="d-flex">
          <v-select
            v-bind="basic"
            class="mr-3"
            label="Filter By"
            v-model="filterCriteria.status"
            :items="statusOptions"
            item-text="text"
            item-value="value"
          ></v-select>

          <v-select
            v-bind="basic"
            class="mr-3"
            label="Job Type"
            v-model="filterCriteria.job_type"
            :items="jobTypeOptions"
            item-text="title"
            item-value="id"
          ></v-select>
        </div>
        <div class="d-flex pt-5">
          <v-select
            v-bind="basic"
            class="mr-3"
            label="Job Status"
            v-model="filterCriteria.job_status"
            :items="jobStatusOptions"
            item-text="title"
            item-value="id"
          ></v-select>

          <v-select
            v-bind="basic"
            class="mr-3"
            label="Job Priority"
            v-model="filterCriteria.job_priority"
            :items="jobPriorityOptions"
            item-text="title"
            item-value="id"
          ></v-select>
        </div>
      </div>

      <div class="col-md-4">
        <v-text-field
          class="mr-3"
          v-bind="basic"
          label="Search"
          append-icon="search"
          value=""
          outlined
          v-model="filterCriteria.q"
        ></v-text-field>
        <v-select
          v-bind="basic"
          class="mr-3 pt-5"
          label="Customer"
          v-model="filterCriteria.customer"
          :items="customerOptions"
          item-text="full_name"
          item-value="id"
        ></v-select>
      </div>

      <slot name="menu"></slot>
    </div>
    <!-- <div class="row">
      <div class="col-md-4">
        <v-select
          v-bind="basic"
          class=""
          label="Invoice"
          v-model="filterCriteria.customer"
          :items="customerOptions"
          item-text="full_name"
          item-value="id"
        ></v-select>
      </div>
    </div> -->
    <div
      class="d-flex mt-2 mb-5"
      v-if="
        filterCriteria.status == 'start_date' ||
          filterCriteria.status == 'due_date'
      "
    >
      <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="mr-3"
            :value="filterCriteria.date_from"
            clearable
            label="Date From"
            readonly
            v-bind="{ ...basic, ...attrs }"
            v-on="on"
            @click:clear="filterCriteria.date_from = null"
            :rules="[util.validation.required]"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filterCriteria.date_from"
          @change="menu1 = false"
        ></v-date-picker>
      </v-menu>

      <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="mr-3"
            :value="filterCriteria.date_to"
            clearable
            label="Date To"
            readonly
            v-bind="{ ...basic, ...attrs }"
            v-on="on"
            @click:clear="filterCriteria.date_to = null"
            :rules="[util.validation.required]"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filterCriteria.date_to"
          @change="menu2 = false"
        ></v-date-picker>
      </v-menu>
    </div>
    <slot></slot>

    <br />
    <v-pagination
      v-if="hasPagination"
      :length="filterCriteria.length"
      v-model="filterCriteria.page"
    ></v-pagination>
  </div>
</template>
<script>
import util from "@/util/util";

export default {
  props: {
    hasFilter: {
      type: Boolean,
      default: true,
    },
    length: {
      type: Number,
    },
    filterCriteriaProps: {
      type: Object,
      default: () => {
        return {
          q: "",
          page: 1,
          length: 2,
          job_type: null,
          job_status: null,
          job_priority: null,
          start_date: null,
          end_date: null,
          customer: null,
        };
      },
    },
    hasPagination: {
      type: Boolean,
      default: true,
    },
    itemName: {
      type: String,
    },
  },
  data() {
    return {
      util,
      menu1: false,
      menu2: false,
      basic: util.input.basic,
      filterCriteria: this.filterCriteriaProps,
      statusOptions: [],
      jobTypeOptions: [],
      jobStatusOptions: [],
      jobPriorityOptions: [],
      customerOptions: [],
    };
  },
  watch: {
    filterCriteria: {
      handler(n) {
        this.$emit("filter", n);
      },
      deep: true,
    },
    length: function(n) {
      this.filterCriteria.length = n;
    },
  },
  mounted() {
    this.getFilterCriteria();
    this.getJobTypes();
    this.getJobStatus();
    this.getJobPriority();
    this.getCustomer();
  },
  methods: {
    getFilterCriteria() {
      util
        .http({
          url: `filters/job`,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.statusOptions = [];
            res.data.data.forEach((item) => {
              this.statusOptions.push({
                text: util.camelCase(item),
                value: item,
              });
            });
            console.log(this.statusOptions);
          }
        });
    },
    getJobTypes() {
      util
        .http({
          url: `job-type/get-all`,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.jobTypeOptions = res.data.data;
          }
        });
    },
    getJobStatus() {
      util
        .http({
          url: `job-status/get-all`,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.jobStatusOptions = res.data.data;
          }
        });
    },
    getJobPriority() {
      util
        .http({
          url: `job-priority/get-all`,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.jobPriorityOptions = res.data.data;
          }
        });
    },
    getCustomer() {
      util
        .http({
          url: `customer/get-all`,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.customerOptions = res.data.data;
          }
        });
    },
  },
};
</script>
<style scoped>
.bg-btn {
  background: #1d2b58 !important;
}
</style>
